<script>
import TemplateHeaderTable from '@/components/TemplateHeaderTable'

export default {
  data() {
    return {
      dataList: [
        {
          serial: '1',
          mainName: '农村集体“三资”监管系统',
          keys: 'SJDG809SSHDNSCJCJHA',
          opener: 'HSKSWNDSDDOSDKA1',
          ips: '152.188.1.114',
          time: '2021-01-11 08:12:00',
          frequency: '1',
          Way: '1',
          status: '0',
          log: '日志',
          a1: '详情',
        },
        {
          serial: '2',
          mainName: '浙江省海洋渔业执法总队',
          keys: 'DSADSFJSSHNDSSCJCJHA',
          opener: 'HFHFKSIW89DK889WD',
          ips: '112.138.1.114',
          time: '2021-02-01 09:07:00',
          frequency: '0',
          Way: '0',
          status: '1',
          log: '日志',
          a1: '详情',
        },
        {
          serial: '3',
          mainName: '慈溪市气象局气象监测站',
          keys: 'SJDGHSJ088SA80FISS9A90D',
          opener: 'HSKSWNDSDOSDA90D',
          ips: '152.123.1.13',
          time: '2021-02-11 14:22:07',
          frequency: '0',
          Way: '1',
          status: '1',
          log: '日志',
          a1: '详情',
        },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'serial',
          align: 'left',
        },
        {
          title: '时间',
          dataIndex: 'mainName',
          align: 'left',
        },
        {
          title: '数据',
          dataIndex: 'keys',
          align: 'left',
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'left',
          render: ({ text }) => {
            return text == '0' ? (
              <span style={{ color: '#5fce60' }}>成功</span>
            ) : (
              <span style={{ color: '#de350c' }}>失败</span>
            )
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        // url={'/api/dataoperation/collectionMainBody/page'}
        data={this.dataList}
        tableColumns={this.getColumns()}
        ref={'table'}
      />
    )
  },
}
</script>
<style lang="less" scoped>
/deep/.ant-table-selection-column {
  display: none;
}
</style>
